<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" :style="headerStyle"></parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 mx-auto">
              <div class="profile">
                <div class="avatar">
                  <img :src="img" alt="Circle Image" class="img-raised rounded-circle img-fluid">
                </div>
                <div class="name">
                  <h1 class="title h3">Clémence Bauche Sophrologue</h1>
                  <md-button
                    href="https://www.facebook.com/clemence.sophrologue/"
                    target="_blank"
                    rel="noopener"
                    class="md-just-icon md-simple"
                  >
                    <i class="fab fa-facebook-square"></i>
                  </md-button>
                  <md-button
                    href="https://www.linkedin.com/in/clémence-bauche-3ba825135"
                    target="_blank"
                    rel="noopener"
                    class="md-just-icon md-simple"
                  >
                    <i class="fab fa-linkedin"></i>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
          <div class="description pb-5">
            <p>
              Après avoir obtenu mon Diplôme d’État en tant qu’infirmière en 2013, j’ai souhaité élargir mes compétences pour offrir des approches complémentaires à mes patients. Je me suis formée à la sophrologie de la méthode Caycédienne au sein de l’Institut National de l’Enseignement de la Sophrologie à Paris, où j’ai obtenu le titre de Sophrologue certifiée RNCP. Cette formation m’a permis de me spécialiser dans la gestion du stress et du sommeil grâce à deux séminaires dédiés.<br><br>

              Après avoir vécu et pratiqué la sophrologie pendant six ans à Montréal, j’ai enrichi mon approche en me formant à l’EFT (Emotional Freedom Techniques) en 2020. De retour en France, je propose désormais mes consultations en visioconférence ou en cabinet à Carcès dans le Var, en me spécialisant dans l’accompagnement des familles neuroatypiques depuis 2 ans.<br><br>

              La sophrologie et l’EFT sont des outils puissants et accessibles qui s’adaptent aux besoins spécifiques des familles neuroatypiques. Mon objectif est de vous aider à :<br><br>

                - Réguler le stress et les surcharges émotionnelles ou sensorielles.<br>
                - Apporter des solutions concrètes et personnalisées pour améliorer la qualité de vie familiale.<br>
                - Favoriser une meilleure connaissance de soi et une communication bienveillante au sein de la famille.<br>
                - Créer un équilibre où chaque membre peut s’épanouir dans sa singularité.<br><br>

              Je vous accompagne pas à pas pour intégrer des pratiques simples, rapides et efficaces dans votre quotidien, afin de retrouver sérénité, harmonie et confiance dans vos ressources personnelles et familiales.
            </p>
          </div>

          <div class="md-layout">
            <div class="elfsight-app-7a170906-fef3-433c-bb35-4756dd02077d"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  bodyClass: "profile-page",
  metaInfo: {
    title: "Profil Clémence Bauche sophrologue",
    meta: [
      {
        name: "description",
        content:
          "Mon parcours et mes expériences en tant que sophrologue- Clémence Bauche Sophrologue méthode caycedienne",
      },
    ],
    link: [
      { rel: "canonical", href: "https://clemence-sophrologie.fr/profil/" },
    ],
  },
  data() {
    return {};
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/3.svg"),
    },
    img: {
      type: String,
      default: require("@/assets/img/faces/clemence_bauche.jpg"),
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.section
  padding: 0

@media (max-width: 991px)
  .profile-page
    .page-header  
      height: 260px
</style>
