<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" :style="headerStyle"></parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 mx-auto">
              <div class="profile">
                <div class="avatar">
                  <img :src="img" alt="Circle Image" class="img-raised rounded-circle img-fluid">
                </div>
                <div class="name">
                  <h1 class="title h3 text-center">Podcast rencontre avec soi</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="description pb-5">
            <p>
              Rencontre avec soi est un podcast dédié à la connaissance de soi pour aider les femmes à avancer dans leur vie sereinement et en toute confiance.
              Avez-vous l’impression de stagner dans votre vie ? Êtes-vous constamment en train de vous remettre en question et cela vous épuise ? Vous vous sentez perdue ?
              Dans ce podcast je vous partagerai mes expériences et mes conseils personnels et de thérapeute. Ce podcast de développement personnel vous permettra d’avoir tous les outils pour apprendre à vous connaître tant physiquement que mentalement. Mieux vous connaitre pour pouvoir changer votre vie de façon positive.
              Alors envie de vous rencontrer ?<br>
              Rencontre avec soi un podcast de Clémence Bauche infirmière et sophrologue.
            </p>
<iframe class="podcast-iframe" src="https://castbox.fm/app/castbox/player/id3065801?v=8.22.11&autoplay=0" frameborder="0" width="100%" height="500"></iframe>

          </div>

          <div class="description text-center mb-5">
            <h2 class="title">Retrouvez moi sur les réseaux sociaux&nbsp;!</h2>
            <md-button
              href="https://www.facebook.com/clemence.sophrologue/"
              target="_blank"
              rel="noopener"
              class="md-just-icon md-simple"
            >
              <i class="fab fa-facebook-square"></i>
            </md-button>
            <md-button
              href="https://www.linkedin.com/in/clémence-bauche-3ba825135"
              target="_blank"
              rel="noopener"
              class="md-just-icon md-simple"
            >
              <i class="fab fa-linkedin"></i>
            </md-button>

            <md-button
              href="https://www.youtube.com/channel/UCMfwF2_pOuz6aDNfWhJ8Pzg"
              target="_blank"
              rel="noopener"
              class="md-just-icon md-simple"
            >
              <i class="fab icon2-youtube-play"></i>
            </md-button>
            <md-button
              href="https://open.spotify.com/show/70QUxdudeRzyxOUggOQ4HE?si=RIo4Z7JHS026d2DF1Qrs5A"
              target="_blank"
              rel="noopener"
              class="md-just-icon md-simple"
            >
              <i class="fab icon2-spotify"></i>
            </md-button>
            <md-button
              href="https://www.instagram.com/clem_sophrologue_atypique/"
              target="_blank"
              rel="noopener"
              class="md-just-icon md-simple"
            >
              <i class="fab icon2-instagram"></i>
            </md-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  bodyClass: "podcast-page",
  metaInfo: {
    title: "Podcast Rencontre avec soi par Clémence Bauche",
    meta: [
      {
        name: "description",
        content:
          "Podcast rencontre avec soi disponible sur toutes les plateformes d'écoute- Clémence Bauche Sophrologue méthode caycedienne",
      },
    ],
    link: [
      {
        rel: "canonical",
        href: "https://clemence-sophrologie.fr/podcast-rencontre-avec-soi/",
      },
    ],
  },
  data() {
    return {};
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/3.svg"),
    },
    img: {
      type: String,
      default: require("@/assets/img/rencontre.jpg"),
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.section
  padding: 0

@media (max-width: 991px)
  .profile-page
    .page-header  
      height: 260px
</style>
