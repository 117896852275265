<template>
  <div class="wrapper">
    <parallax
      class="section page-header header-filter"
      :style="headerStyle"
    ></parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-80 mx-auto">
              <div class="text-center">
                <div class="name">
                  <h1 class="title">Séances de sophrologie</h1>
                </div>
              </div>

              <!-- Début de widget en ligne Calendly -->
              <div class="calendly-inline-widget" data-url="https://calendly.com/clemencebauche-sophrologie?primary_color=5569d4" style="min-width:320px;height:1100px;"></div>
              <!-- Fin de widget en ligne Calendly -->
            </div>
          </div>
          <div class="text-center mb-5">
            <h2 style="font-weight:bold">Séances en entreprise:</h2>
            <span>Sur devis, me contacter <a href="mailto:clemence.sophrologie@gmail.com" target="_blank" rel="noopener">ici</a></span>

            <h2 style="font-weight:bold">Vous avez des questions ?</h2>
            <a
              href="mailto:clemence.sophrologie@gmail.com"
              target="_blank"
              rel="noopener"
            >
              <md-button class="md-success">Contactez-moi</md-button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { NavTabsCard } from "@/components";
// import { Tabs } from "@/components";

export default {
  bodyClass: "profile-page",
  components: {
    // NavTabsCard,
    // Tabs
  },
  metaInfo: {
    title: "Clémence Bauche sophrologue - Séances, services et tarifs",
    meta: [
      {
        name: "description",
        content:
          "Séances de sophrologie individuelles ou en groupe pour enfants, adolescents et adultes- Clémence Bauche Sophrologue méthode caycedienne",
      },
    ],
    link: [
      { rel: "canonical", href: "https://clemence-sophrologie.fr/services/" },
    ],
  },
  data() {
    return {};
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/3.svg"),
    },
    img: {
      type: String,
      default: require("@/assets/img/faces/clemence_bauche.jpg"),
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.section
  padding: 0
</style>
