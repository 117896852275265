<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" :style="headerStyle"></parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-96 mx-auto">
              <div class="text-center">
                <div class="name">
                  <h1 class="title">Boutique Clemence Bauche sophrologue méthode caycedienne</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="description pb-3">
            <h2>Relaxation pour enfant/ado l'arbre de la confiance</h2>
            <p>
              Besoin que votre enfant ou votre ado reprenne confiance en soi et en ses capacités ? Cet audio de moins de 15 min va apporter de la détente corporelle et mentale grâce à un voyage sur le thème de la confiance. Assis ou allongé, cette relaxation se fait autant de fois qu'on le souhaite et à n'importe quel moment de la journée.
            </p>
          </div>
          <div class="description pb-3">
            <md-button href="https://clemence.podia.com/relaxation-pour-enfant-l-arbre-de-la-confiance/buy?offer_id=2737890&payment_terms=full" target="_blank" rel="noopener" class="md-success">J'achète ma relaxation</md-button>
          </div>
          <!-- <div class="description pb-3">
            <h2>Méditation guidée gratuite - Intégrer du positif avec Clémence Bauche, Sophrologue Méthode Caycédienne</h2>
            <p>
              Bienvenue dans cette méditation guidée gratuite conçue pour intégrer du positif jusque dans vos tissus et vos cellules. Je suis Clémence Bauche, sophrologue spécialisée en méthode Caycédienne. Cette séance est idéale pour vous offrir un moment de bien-être, vous recentrer et ancrer des émotions positives dans votre corps.
            </p>
            <h3>Pourquoi pratiquer cette méditation guidée ?</h3>
            <p>La sophrologie est une méthode douce qui aide à harmoniser le corps et l’esprit. Cette méditation, accessible à tous, permet : D’évacuer les tensions accumulées. De renforcer votre capacité à ressentir des émotions positives. De retrouver calme et sérénité au quotidien.</p>
            <h3>Pour qui est cette séance ?</h3>
            <p>Cette méditation est parfaite si vous souhaitez découvrir les bienfaits de la sophrologie ou approfondir votre pratique. Que vous soyez dans le Var ou ailleurs, laissez-vous guider par ma voix pour un voyage intérieur vers le positif.</p>
            <h3>Comment intégrer la sophrologie dans votre quotidien ?</h3>
            <p>En tant que sophrologue dans le Var, j’accompagne les adultes, enfants et adolescents dans leur quête de mieux-être. La sophrologie vous aide à mieux gérer le stress, les émotions et les défis de la vie quotidienne.<br><br>Pour découvrir d'autres conseils bien-être et des séances guidées, suivez-moi sur Instagram : <a href="https://www.instagram.com/clem_sophrologue_atypique/" target="_blank" rel="noopener">@clem_sophrologue_atypique</a>.</p>
            <h3>Regardez maintenant ma méditation guidée</h3>
            <p>Prenez quelques minutes pour vous détendre et explorer cette séance dédiée à l’intégration du positif :</p>
          </div>
          <div class="description pb-3">
            <md-button href="https://youtu.be/cFsRx4YuAl8?si=-5p4TtYcSaKXKJOh" target="_blank" rel="noopener" class="md-success">J'accède gratuitement à la vidéo</md-button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  bodyClass: "profile-page",
  metaInfo: {
    title: "Boutique Clemence Bauche sophrologue méthode caycedienne",
    meta: [
      {
        name: "description",
        content: "Sophrologue méthode caycedienne - kit de rentrée sans stress",
      },
    ],
    link: [
      {
        rel: "canonical",
        href: "https://clemence-sophrologie.fr/boutique/",
      },
    ],
  },
  mounted() {
    if (
      !document.querySelector(
        'script[type="application/ld+json"][data-script="product"]',
      )
    ) {
      const script1 = document.createElement("script");
      script1.type = "application/ld+json";
      script1.dataset.script = "product";
      script1.innerHTML = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Product",
        name: "Relaxation pour enfant/ado : L'arbre de la confiance",
        image:
          "https://clemence.podia.com/content-assets/public/eyJhbGciOiJIUzI1NiJ9.eyJvYmplY3Rfa2V5Ijoib2xuMmIzOWR0Nnpzamp2dmZ1bWVkbWlpZGszaSIsImRvbWFpbiI6ImNsZW1lbmNlLnBvZGlhLmNvbSJ9.j5MlXb6BkLL5AqSY9MZ_e36X5G6QhmoPH1h6f04jhbQ",
        description:
          "Besoin que votre enfant ou votre ado reprenne confiance en soi et en ses capacités ? Cet audio de moins de 15 minutes va apporter de la détente corporelle et mentale grâce à un voyage sur le thème de la confiance. Assis ou allongé, cette relaxation se fait autant de fois qu'on le souhaite et à n'importe quel moment de la journée.",
        url:
          "https://clemence.podia.com/relaxation-pour-enfant-l-arbre-de-la-confiance/buy?offer_id=2737890&payment_terms=full",
        offers: {
          "@type": "Offer",
          price: "19.50",
          priceCurrency: "EUR",
          availability: "https://schema.org/InStock",
          url:
            "https://clemence.podia.com/relaxation-pour-enfant-l-arbre-de-la-confiance/buy?offer_id=2737890&payment_terms=full",
        },
        brand: {
          "@type": "Brand",
          name: "Clemence Bauche Sophrologue",
        },
      });
      document.head.appendChild(script1);
    }
  },
  data() {
    return {};
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/3.svg"),
    },
    img: {
      type: String,
      default: require("@/assets/img/faces/clemence_bauche.jpg"),
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.section
  padding: 0
</style>
